@import '../../../App.scss';

.about-content-text {
  grid-column: center-start / col-end 6;
  margin-right: 1rem;

  @include respond(tab-port) {
    grid-column: center-start / center-end;
  }
}

.company-info {
  background-color: $color-primary;
  grid-column: col-start 7 / center-end;
  color: $color-white;
  padding: 3rem;
  border-radius: 0.5rem;

  @include respond(tab-port) {
    grid-column: center-start / center-end;
    margin-top: 2rem;
  }
}

.company-info-section {
  margin-bottom: 2rem;
}

.company-info-heading {
  // grid-column: 1 / -1;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: underline;
}

.company-info-data {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // grid-row-gap: 0.5rem;
}

.company-info-data-heading {
  grid-column: 1 / span 2;
  font-size: 1.7rem;
  font-variant: small-caps;
}

.company-info-data-data {
  font-size: 1.4rem;
}

.company-info-section-data {
  font-size: 1.4rem;
}

.company-snapshot {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  &-heading {
    grid-column: 1 / 2;
    font-size: 1.5rem;
  }

  &-info {
    grid-column: 2/4;
    display: flex;
    justify-content: flex-start;
    font-size: 1.5rem;
  }
}
