@import '../../../App.scss';

.contactus {
  grid-column: center-start / center-end;

  display: flex;

  @include respond(tab-land) {
    flex-direction: column;
  }

  &-form {
    // background-color: aqua;
    flex: 1;

    h3.contactus-form-heading {
      color: $color-primary-dark;
      font-variant: small-caps;
      line-height: 6rem;
    }

    button.submit-button {
      font-size: 2rem;
      padding: 1.5rem 5rem;
      background-color: rgba($color-primary-light, 0.2);
      border: none;
      border-radius: 5px;

      &:hover {
        background-color: rgba($color-primary-light, 0.25);
      }
    }
  }
  &-details {
    // background-color: burlywood;
    flex: 1;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: minmax(min-content, max-content);

    h3.contactus-details-heading {
      grid-column: 1 / -1;
      color: $color-primary-dark;
      font-variant: small-caps;
      line-height: 6rem;
    }

    h4 {
      color: $color-primary-light;
      margin-bottom: 1rem;
    }

    &-icon {
      grid-column: 1 / 1;
      font-size: 3rem;
      color: $color-primary;
      padding: 2rem 0;
    }

    &-address,
    &-phone,
    &-fax {
      grid-column: 2 / -1;
      padding: 2rem 0;

      h4 {
        letter-spacing: 2px;
        font-variant: small-caps;
      }
    }

    @include respond(tab-land) {
      margin-top: 5rem;
    }
  }
}
