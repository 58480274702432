@import '../../../App.scss';

.careers {
  grid-column: center-start / center-end;
  margin-bottom: 8rem;

  // display: grid;

  h1.coming-soon {
    font-size: 4rem;
    color: $color-primary;
    font-variant: small-caps;
    letter-spacing: 3px;
    border: 2px solid $color-primary;
    text-align: center;
    padding: 2rem 0;
    margin-top: 3rem;
  }
}
