@import '../../../../App.scss';

.header {
  height: 85vh;
  background-image: linear-gradient(
      to right bottom,
      rgba($color-primary-light, 0.8),
      rgba($color-primary-dark, 0.8)
    ),
    url(../../../img/redlaser.jpg);
  background-size: cover;
  background-position: top;
  position: relative;

  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 50% 100%, 0 75vh);
    clip-path: polygon(0 0, 100% 0, 100% 75vh, 50% 100%, 0 75vh);
    height: 95vh;
  }

  @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
    only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
    only screen and (min-width: 125em) {
    background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.8),
        rgba($color-primary-dark, 0.8)
      ),
      url(../../../img/redlaser.jpg);
  }
}

.logo-box {
  position: absolute;
  top: 4rem;
  left: 4rem;
}

.header-logo {
  height: 3.5rem;
}

.header-text-box {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.heading-primary {
  color: $color-white;
  text-transform: uppercase;

  backface-visibility: hidden;
  margin-bottom: 6rem;

  &-main {
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 3.5rem;

    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @include respond(phone) {
      letter-spacing: 1rem;
      font-family: 5rem;
    }
    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
  }

  &-sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation: moveInRight 1s ease-out;

    @include respond(phone) {
      letter-spacing: 0.5rem;
    }
  }
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
