@import '../../../App.scss';

.testimonials {
  grid-column: center-start / center-end;
  // background-color: beige;
  padding: 3rem;

  .testimonial {
    display: flex;
    padding-bottom: 2rem;
    margin-bottom: 5rem;

    border-bottom: 1px solid $color-primary;

    .client-image {
      width: 8vw;

      img {
        display: inline-block;
        border-radius: 100%;
        width: 100%;
      }
    }

    @include respond(tab-land) {
      flex-direction: column;

      .client-image {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 30rem;
        }
      }
    }
  }

  .client-details {
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .client-identity {
      font-size: 1.7rem;
      color: $color-primary;
      letter-spacing: 2px;
    }

    .client-testimonial {
      font-size: 1.2rem;
      color: $color-primary;
    }
  }
}
