@import '../../../App.scss';

.inner-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.inner-logo {
  flex: 1;

  img {
    width: 40%;
  }
}

.top-contact {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  color: $color-primary-dark;
  font-size: 2rem;

  .callno {
    flex: 1;
    span {
      margin-right: 1rem;
    }
  }

  .top-social-icons {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    span {
      padding: 0 2rem;
    }
  }

  @include respond(tab-port) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.inner-banner {
  height: 50vh;
  margin-bottom: 0rem;
  background-image: linear-gradient(
      to right bottom,
      rgba($color-primary-light, 0.8),
      rgba($color-primary-dark, 0.8)
    ),
    url(../../img/usflag02.jpg);
  // background-size: cover;
  // background-position: top;
  // position: relative;

  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 35vh, 0 45vh);
    clip-path: polygon(0 0, 100% 0, 100% 35vh, 0 45vh);
    height: 50vh;
  }

  @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
    only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
    only screen and (min-width: 125em) {
    background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.8),
        rgba($color-primary-dark, 0.8)
      ),
      url(../../img/usflag02.jpg);
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

h1.inner-page-title {
  // background-color: yellow;
  color: $color-white;
  font-size: 6rem;
  // font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  transform: translateY(-75%);

  @include respond(phone) {
    transform: translateY(-150%);
  }
}
