// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: $color-grey-dark;

  // font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  h1 {
    font-weight: 300;
    font-size: 6rem;
    line-height: 1.167;
    letter-spacing: -0.01562em;
  }

  h2 {
    font-weight: 300;
    font-size: 3.75rem;
    line-height: 1.2;
    letter-spacing: -0.00833em;
  }

  h3 {
    font-weight: 400;
    font-size: 3rem;
    line-height: 1.167;
    letter-spacing: 0em;
  }

  h4 {
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }

  h5 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
  }

  h6 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }

  h2.inner-main-heading {
    grid-column: center-start / center-end;
    color: $color-primary-dark;
    font-size: 5rem;
    text-align: center;
    margin: 2rem 0;
    letter-spacing: 3px;
  }

  h3.inner-para-heading {
    grid-column: center-start / center-end;
    color: $color-primary-dark;
    font-size: 4rem;
    margin: 2rem 0;
    font-variant: small-caps;
    letter-spacing: 3px;
  }

  p.innerpara {
    grid-column: center-start / center-end;
    font-size: 2rem;
    color: $color-primary-dark;
    line-height: 4rem;
    letter-spacing: 2px;
  }

  .inner-content {
    margin: 0 0 4rem 0;
  }
}
