@import '../../../../App.scss';

.clientslider {
  // background-color: lightgrey;
  margin: 4rem 0;
  // border-top: 2px solid $color-primary;
  // border-bottom: 2px solid $color-primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1.our-client-logos {
  color: $color-primary;
  margin-top: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

// .slick-slide > div {
//   display: grid;
//   place-items: center;
//   width: 80%;
//   margin-top: 50px;
//   margin: auto;
//   height: 500px;
//   padding: 0px;
//   background: red;
// }

.homelogoslider {
  width: 80%;
  padding: 5rem 0;
}

.logoslider {
  height: 20rem;
  padding: 0 5rem;

  img {
    margin: auto;
    height: 10rem;
  }
}
