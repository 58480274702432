@import '../../../App.scss';

.cloudcomputing {
  grid-column: center-start / center-end;
  margin-bottom: 8rem;

  &-text {
    font-size: 2rem;
    color: $color-primary;
    line-height: 4rem;
    margin-bottom: 3rem;

    span {
      font-weight: bold;
    }
  }

  &-benefits {
    margin-bottom: 3rem;

    &-heading {
      color: $color-primary;
      font-size: 2.5rem;
      margin: 2rem 0;
      font-variant: small-caps;
      letter-spacing: 2px;
    }

    &-content {
      color: $color-primary;
      font-size: 2rem;
      margin: 1rem 0;

      span {
        margin-left: 2rem;
        margin-right: 2rem;
        // transform: translateY(25px);
      }
    }
  }
}
