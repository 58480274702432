@import '../../../App.scss';

.stars3-page-title {
  // background-color: cyan;
  font-size: 4rem;

  @include respond(phone) {
    font-size: 2.3rem;
    letter-spacing: 2px;
    transform: translateY(-150%);
  }
}

.stars3 {
  grid-column: center-start / center-end;

  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include respond(phone) {
    grid-template-columns: 1fr;
  }

  &-title {
    h3 {
      color: $color-primary;
      font-size: 2.5rem;
      line-height: 7rem;
      font-variant: small-caps;
      letter-spacing: 3px;

      @include respond(tab-land) {
        // font-size: 2.5rem;
        // line-height: 3rem;
      }

      @include respond(tab-port) {
        font-size: 2rem;
        letter-spacing: 2px;
      }

      @include respond(phone) {
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 2rem;
      }
    }
  }

  &-data {
    flex: 50%;
    display: flex;
    align-items: center;

    color: $color-primary;
    font-size: 2rem;

    @include respond(tab-land) {
      font-size: 1.75rem;
    }

    @include respond(tab-port) {
      font-size: 1.5rem;
      letter-spacing: 2px;
    }

    @include respond(phone) {
      font-size: 1.25rem;
      letter-spacing: 0;
      margin-bottom: 2rem;
    }

    ul,
    li {
      list-style: none;
    }
  }

  &-data.address {
    margin-bottom: 2rem;
  }
}
