@import '../../../App.scss';

.verticals {
  grid-column: center-start / center-end;
  margin-bottom: 8rem;
}

p.verticals-intro {
  font-size: 2rem;
  color: $color-primary;
  line-height: 4rem;
  margin-bottom: 3rem;
}

.verticals-info {
  grid-column: center-start / center-end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3rem;
  justify-items: center;

  @include respond(tab-port) {
    grid-template-columns: 1fr;
  }

  .verticals-single {
    &-info {
      width: 70%;

      a {
        text-decoration: none;
      }
    }

    &-heading {
      color: $color-primary-dark;
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    &-image {
      font-size: 6rem;
      color: $color-primary-dark;
    }

    &-summary {
      font-size: 1.75rem;
      color: $color-primary-dark;
    }
  }
}
