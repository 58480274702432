@import '../../../App.scss';

.certifications {
  grid-column: center-start / center-end;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;

  @include respond(tab-port) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
  }

  .certificate {
    text-align: center;
    border: 1px solid $color-primary-dark;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-image {
      img {
        width: 100%;
        max-width: 100%;
        border-radius: 5px;

        transition: all 350ms ease;
        cursor: pointer;

        &:hover {
          filter: opacity(0.9);
        }
      }
    }

    &-content {
      font-size: 1.7rem;
      background-color: $color-primary-dark;
      color: $color-white;
      border-radius: 5px;

      &-text {
        font-size: 1.5rem;
      }
    }
  }
}

.certificatemodal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(#ffdab9, 0.85);
  transition: opacity 0.7s ease, visibility 0.7s ease transform 0.7s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 5000;

  &.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    svg {
      position: fixed;
      top: 10rem;
      right: 10rem;
      width: 10rem;
      height: 8rem;
      color: $color-primary-dark;
      cursor: pointer;
    }
  }

  img {
    width: 55vw;
    max-width: 55vw;
    display: block;
    border: 4px solid $color-primary-dark;
    border-radius: 10px;
    padding: 5px;
    // width: auto;
    // max-width: 100%;
    // height: auto;
    // max-height: 100%;
    // line-height: 0;
    // box-sizing: border-box;
    // padding: 2rem 0 2rem 0;
  }

  .certificatemodal-text,
  .modalimage-text {
    color: $color-primary-dark;
    margin-top: 2rem;
    letter-spacing: 3px;
  }

  a.modallink {
    text-decoration: none;
  }
}
