// COLORS
$color-primary: #720909;
$color-primary-light: #b10f0f;
$color-primary-dark: #4b0505;

$color-secondary: #081d40;
$color-secondary-light: #0b3f97;
$color-secondary-dark: #030c1c;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
