@import '../../../App.scss';

.footer {
  grid-column: full-start / full-end;
  background-color: $color-primary;
  padding: 10rem 0;

  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, 1fr));
  grid-gap: 10rem;

  @include respond(tab-land) {
    grid-template-columns: repeat(3, minmax(min-content, 1fr));
    grid-column-gap: 6rem;
    grid-row-gap: 4rem;
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-site-pages {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.footer-page span {
  margin-right: 0.5rem;
}

.footer-page a {
  display: inline-block;
  color: $color-white;
  font-size: 1.5rem;
  text-decoration: none;
  font-variant: small-caps;
  letter-spacing: 3px;
  padding: 0.5rem 0;
}

.footer-contact {
  color: $color-white;
  font-size: 1.5rem;
  text-transform: uppercase;

  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    display: block;
    margin: 0.4rem 0;

    &.company-name {
      font-size: 1.6rem;
    }
  }
}

.footer-social {
  display: grid;
  grid-template-columns: repeat(4, max(5rem));
  color: $color-white;
  font-size: 3rem;
  justify-content: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.copyright {
  grid-column: full-start / full-end;
  padding: 0.5rem 0;
  color: $color-primary;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;

  span {
    margin-top: 3px;
    margin-right: 1rem;
  }
}
