form {
  label {
    display: block;
    font-size: 2rem;
    color: $color-primary-light;
  }

  input,
  textarea {
    padding: 1rem;
    font-size: 1.5rem;
    width: 70%;
    border: 1px solid #000;
    border-radius: 5px;
    margin-bottom: 2rem;

    &::placeholder {
      font-size: 1.5rem;
      color: rgba($color-primary-dark, 0.8);
      font-variant: small-caps;
    }
  }
}
