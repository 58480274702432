@import '../../../App.scss';

.pastperformances {
  grid-column: center-start / center-end;

  .casestudy {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    margin-bottom: 5rem;
    border-left: 1px solid $color-primary-dark;
    border-top: 1px solid $color-primary-dark;
    border-right: 1px solid $color-primary-dark;
    border-radius: 10px;

    &-heading {
      grid-column: 1 / span 1;

      font-size: 2rem;
      font-variant: small-caps;
      font-weight: bold;
      color: $color-primary;
      border-right: 1px solid $color-primary-dark;
      border-bottom: 1px solid $color-primary-dark;

      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(tab-port) {
        grid-column: 1 / -1;
        padding: 0.7rem 0;
        border-right: none;
      }
    }

    &-detail {
      grid-column: 2 / -1;
      padding: 1rem 0 1rem 1rem;

      font-size: 2rem;
      color: $color-primary;
      border-bottom: 1px solid $color-primary-dark;

      white-space: pre-line;
      vertical-align: bottom;

      @include respond(tab-port) {
        grid-column: 1 / -1;
        padding: 0.3rem 1rem;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-detail.clientidentity {
      font-variant: small-caps;
      background-color: $color-primary;
      color: #fff;
    }
  }
}
