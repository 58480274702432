@import '../../../App.scss';

.fcv-title {
  // background-color: cyan;
  font-size: 4rem;

  @include respond(phone) {
    font-size: 2.3rem;
    letter-spacing: 2px;
    transform: translateY(-150%);
  }
}

.fcv {
  grid-column: center-start / center-end;
  margin-bottom: 8rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .left-col,
  .right-col {
    text-align: center;

    .fcv-button {
      display: inline-block;
      width: 75%;
      font-size: 2.5rem;
      padding: 2rem 3rem;
      background-color: $color-primary;
      color: white;
      border: none;
      border-radius: 10px;
      font-variant: small-caps;
      letter-spacing: 3px;
      cursor: pointer;

      @include respond(phone) {
        font-size: 1.56rem;
      }
    }
  }

  @include respond(tab-port) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
